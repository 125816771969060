export const mockQuotes = [
    `I drink your milkshake!`,
    `Zed''s dead, baby. Zed''s dead.`,
    `They call it a Royale with cheese.`,
    `Just when I thought I was out, they pull me back in.`,
    `Yo, Adrian!`,
    `First things first! Where''s the shitter?`,
    `So, you guys like to tell jokes and giggle and kid around, huh?`,
    `Oh man, I shot Marvin in the face.`,
    `What do you mean "look in the dog?"`,
    `And now, little man, I give the watch to you.`,
    `I mean, funny like I''m a clown? I amuse you?`,
    `Here''s Johnny!`,
    `Yippie-ki-yay, motherfucker!`,
    `You can''t handle the truth!`,
    `Alright meow where were we?`,
    `I want a God-dam litre of cola!`,
    `You mean Shenanigans?`,
    `Alright, who wants a moustache ride?`,
    `YOU BOYS LIKE MEX-E-CO?!?! YEEEEEEEEE!`,
    `Canada, eh? Almost made it.`,
    `Mother of God.`,
    `I am all that is MAN!`,
    `Sorry about that, Bruce. These boys get that syrup in ''em, they get all antsy in their pantsy.`,
    `What do you mean "look in the dog?"`,
    `Do you like dags?`,
    `Should I call you Bullet? Tooth?`,
    `Well, if this is it, old boy, I hope you don''t mind if I go out speaking the King''s.`,
    `Oooh, that''s a bingo! Is that the way you say it?`,
    `Frankly, my dear, I don''t give a damn.`,
    `Obviously you''re not a golfer.`,
    `Fuck it, Dude. Let''s go bowling.`,
    `Mind if I do a J?`,
    `This is not ''Nam. This is bowling. There are rules.`,
    `What''s a pederast, Walter?`,
    `This aggression will not stand, man.`,
    `He fixes the cable?`,
    `Shut the fuck up, Donny!`,
    `That rug really tied the room together.`,
    `Who the fuck are the Knutsens?`,
    `You see what happens, Larry?!`,
    `Forget it, Donny, you''re out of your element!`,
    `You said it, man. Nobody fucks with the Jesus.`,
    `He''s a good man....and thorough.`,
    `Calmer then you are.`,
    `Are these the Nazis, Walter?`,
    `Oh, behave!`,
    `I have had it with these motherfucking SNAKES on this motherfucking PLANE!`,
    `Son, you got a panty on your head.`,
    `What the hell kind of name is I.B. Bangin''?`,
    `People see the ring, they think at least somebody can stand the son of a bitch.`,
]
